import { FC } from 'react';
import { ITemplateResponse } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';
import server from 'config';
import {
  GetStaticPaths,
  GetStaticPathsResult,
  GetStaticProps,
  GetStaticPropsResult,
} from 'next';
import dynamic from 'next/dynamic';
import Error from 'next/error';
import Head from 'next/head';
import { useRouter } from 'next/router';

import AdvantageSherpas from '@/components/GiveCourses/AdvantageSherpas';
import HowItWorksTeacher from '@/components/GiveCourses/HowItWorksTeacher/HowItWorksTeacher';
import HowToBecomeSherpas from '@/components/GiveCourses/HowToBecomeSherpas/HowToBecomeSherpas';
import LandingTeacherHero from '@/components/GiveCourses/LandingHeroTeacher/LandingHeroTeacher';
import LandingCarroussel from '@/components/GiveCourses/LandingTeacherCarroussel/LandingTeacherCarroussel';
import {
  AdvantageSherpasDefault,
  HowToBecomeSherpasDefault,
  LandingHeroTeacherDefault,
  LandingTeacherCarrousselDefault,
} from '@/components/GiveCourses/seo.content.default';
import Seo from '@/components/Landing/Seo/Seo';
import LandingSEOParagraphs from '@/components/Landing/Seo/SEOParagraph/LandingSEOParagraph';
import Visio from '@/components/Landing/Visio/Visio';
import SEOFormattedMessage from '@/generic/SEOFormattedMessage/SEOFormattedMessage';
import LoaderAnimation from '@/Molecules/Loader/LoaderAnimation';
import getStructuredData from '@/utils/structuredData';

const DynamicFaq = dynamic(
  () => import('@/components/GiveCourses/TeacherFAQ/TeacherFAQ'),
  { ssr: false }
);

const LandingTeacher: FC<ITemplateResponse & { errorCode?: number }> = ({
  template,
  errorCode,
  breadcrumbs,
  cityName,
  isOnline,
  searchAddress,
  subjectName,
}) => {
  const { isFallback } = useRouter();
  if (isFallback) {
    return <LoaderAnimation />;
  }

  if (errorCode) {
    return (
      <Error
        statusCode={errorCode}
        title={errorCode === 410 ? 'Gone' : 'not Found'}
      />
    );
  }

  return (
    <>
      <Head>
        <title>{template?.metaTitle}</title>
        <meta name="description" content={template?.metaDescription} />
        <link rel="canonical" href={template?.canonical} />
        <script
          key="structured-data"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              getStructuredData({
                subject: subjectName,
                city: cityName,
                level: null,
                h1Content: template?.h1Content,
                metaDescription: template?.metaDescription,
                canonical: template?.canonical,
                latitude: searchAddress?.latitude,
                longitude: searchAddress?.longitude,
                postalCode: searchAddress?.zipCode,
                isOnline,
                page: 'donner-cours-particuliers',
              })
            ),
          }}
        />
      </Head>
      <LandingTeacherHero
        title={
          template?.h1Content ? (
            <SEOFormattedMessage
              id="landing.teacher.seo.give.course.hero.title"
              defaultMessage={template.h1Content}
            />
          ) : (
            LandingHeroTeacherDefault.defaultTitle
          )
        }
        subtitle={
          template?.h2Content1 ? (
            <SEOFormattedMessage
              id="landing.teacher.seo.give.course.hero.subtitle"
              defaultMessage={template.h2Content1}
            />
          ) : (
            LandingHeroTeacherDefault.defaultSubtitle
          )
        }
      >
        {breadcrumbs.length >= 0 && (
          <LandingTeacherHero.Breadcrumb>
            {breadcrumbs.map((breadcrumb, index) => (
              <LandingTeacherHero.Breadcrumb.Item
                content={`${index + 1}`}
                label={breadcrumb.title}
                href={breadcrumb.url}
                isLastElement={index === breadcrumbs.length - 1}
                key={`breadcrumb-${index}`}
              />
            ))}
          </LandingTeacherHero.Breadcrumb>
        )}
      </LandingTeacherHero>
      <AdvantageSherpas
        title={
          template?.h2Content2 ? (
            <SEOFormattedMessage
              id="landing.teacher.seo.give.course.advantages.subtitle"
              defaultMessage={template.h2Content2}
            />
          ) : (
            AdvantageSherpasDefault.defaultTitle
          )
        }
        subtitle={AdvantageSherpasDefault.defaultSubtitle}
      />
      <HowItWorksTeacher />
      <Visio isTeacher />
      <LandingCarroussel
        title={
          template?.h2Content5 ? (
            <SEOFormattedMessage
              id="landing.teacher.seo.give.course.carroussel.title"
              defaultMessage={template.h2Content5}
            />
          ) : (
            LandingTeacherCarrousselDefault.defaultTitle
          )
        }
        subtitle={
          template?.h2Content4 ? (
            <SEOFormattedMessage
              id="landing.teacher.seo.give.course.carroussel.subtitle"
              defaultMessage={template.h2Content4}
            />
          ) : (
            LandingTeacherCarrousselDefault.defaultSubtitle
          )
        }
      />
      <HowToBecomeSherpas
        title={
          template?.h2Content3 ? (
            <SEOFormattedMessage
              id="landing.teacher.seo.give.course.howTo.title"
              defaultMessage={template.h2Content3}
            />
          ) : (
            HowToBecomeSherpasDefault.defaultTitle
          )
        }
        subtitle={HowToBecomeSherpasDefault.defaultSubtitle}
        featureLabel={HowToBecomeSherpasDefault.defaultLabel}
        featureBackground={HowToBecomeSherpasDefault.defaultBackgroundUrl}
        howToSections={HowToBecomeSherpasDefault.defaultSections}
      />
      <DynamicFaq />
      <Seo
        subjects={template?.subjects}
        levels={template?.levels}
        cities={template?.cities}
      />
      {!!template?.seoParagraphs?.length && (
        <LandingSEOParagraphs
          title={
            <SEOFormattedMessage
              id="landing.teacher.seo.give.course.seoSection.title"
              defaultMessage={template?.h2Content10}
            />
          }
          sectionData={template?.seoParagraphs}
        />
      )}
    </>
  );
};

export const getStaticPaths: GetStaticPaths =
  async (): Promise<GetStaticPathsResult> => {
    // const { data } = await axios.get<string[]>(
    //   `${server}content/donner-cours-particuliers`
    // );

    // On ne build qu'une page pour gagner du temps tout en gardant une certaine sécurité
    const data = ['maths'];
    return {
      paths: data.map((seo: string) => ({ params: { seo } })),
      fallback: 'blocking',
    };
  };

export const getStaticProps: GetStaticProps<ITemplateResponse> = async ({
  params,
}): Promise<GetStaticPropsResult<ITemplateResponse>> => {
  const { seo } = params as { seo: string };
  try {
    const { data } = await axios.get<ITemplateResponse>(
      `${server}template/donner-cours-particuliers/${seo}`,
      { validateStatus: (status) => status === 200 }
    );

    return {
      props: data,
      revalidate: 3600 * 24 * 7, // Michel a validé 1 semaine de cache ici
    };
  } catch (error) {
    return { notFound: true };
  }
};

export default LandingTeacher;
